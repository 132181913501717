import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import { TitleStack } from "../../theme/standarts_styles";

const ContractTabs = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const tabs = [
    { label: "Договоры", path: "contracts" },
    { label: "Трудоустройство", path: "employments" },
  ];

  // if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
  //   let redirectPath = location.pathname;
  //   if (!location.pathname.endsWith("/")) {
  //     redirectPath += "/";
  //   }
  //   redirectPath += tabs[0].path;
  //   return <Navigate to={redirectPath} />;
  // }

  return (
    <Container sx={{ pb: 2 }}>
      <TitleStack>
        <Typography variant="h4">Договоры</Typography>
      </TitleStack>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default ContractTabs;
