import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import AlertMessage from "../../components/Alert/AlertMessage";
import BlockFormModal from "../../components/BlockFormModal";
import CustomViewField from "../../components/CustomViewField";
import SkeletonInput from "../../components/SkeletonInput";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";

const EmployeesForm = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isMobile = useResponsive("down", "md");

  const { employeeId } = useParams();

  const { departments } = useSelector((state) => state.departmentsReducer);

  const { selectedEmployee } = useSelector((state) => state.employeesReducer);

  const { setDataSelectedEmployee, setDepartments, setErrorAlertMessage } =
    useActions();

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    reset,
  } = methods;

  useEffect(() => {
    reset(selectedEmployee);
  }, [reset, selectedEmployee]);

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    const newData = {
      occupation: data?.occupation || null,
      department_id: data.department?.id || null,
      manager_id: data.department?.manager_id || null,
    };

    try {
      await $authHost.put(
        `/users/update/${selectedEmployee.source_id}/`,
        newData,
      );
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.detail ===
          "The manager id cannot be the same as your source id"
      ) {
        setErrorAlertMessage("Пользователь не может быть менеджером для себя");
      }
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/org-structure/employees");
    }
  }, [isSubmitSuccessful, navigate]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/users/${employeeId}/`);

      setDataSelectedEmployee(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [employeeId, setDataSelectedEmployee]);

  const loadDeparts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get("/departments/all/");
      setDepartments(response.data.items);
    } catch (e) {
      console.log(e);
    }
  }, [setDepartments]);

  const handleBack = () => {
    navigate(`/org-structure/employees/${employeeId}`);
  };

  useEffect(() => {
    Promise.all([loadData(), loadDeparts()]).then(() => setLoading(false));
  }, [loadData, loadDeparts]);

  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack>
        <Typography gutterBottom variant="h4">
          Управление {selectedEmployee?.display_name}
        </Typography>
      </TitleStack>
      <Card sx={{ mb: 2 }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              alignItems="flex-start"
              container
              direction="row"
              justifyContent="flex-start"
              sx={{ p: isMobile ? 2 : 5 }}
            >
              {isMobile && (
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mb: 3 }}
                >
                  <Grid item>
                    <Typography color="text.secondary" variant="subtitle1">
                      Редактировать
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleBack} sx={{ p: 0 }}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              <Grid item ld={4} md={12} sm={12} xl={4} xs={12}>
                <Avatar
                  alt="photoURL"
                  id="img"
                  src={selectedEmployee?.photo}
                  sx={{ width: 250, height: 250, mb: 4 }}
                />
                <Typography
                  sx={{
                    mb: 3,
                  }}
                  variant="subtitle1"
                >
                  Орг. структура
                </Typography>

                <SkeletonInput loading={loading} width={"90%"}>
                  <Controller
                    control={control}
                    name="department"
                    render={({
                      field: { onChange, ...field },
                      fieldState: { error },
                    }) => {
                      return (
                        <Autocomplete
                          {...field}
                          getOptionLabel={(option) => option?.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(e, value) => {
                            onChange(value);
                          }}
                          options={departments}
                          renderInput={(params) => (
                            <TextField
                              label="Отдел"
                              {...params}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                          size="small"
                          sx={{
                            mb: 2,
                            pr: 5,
                          }}
                        />
                      );
                    }}
                    rules={
                      {
                        // required: t('main.another.requiredField'),
                      }
                    }
                  />
                </SkeletonInput>

                <SkeletonInput loading={loading} width={"90%"}>
                  <CustomViewField
                    label="Менеджер"
                    loading={false}
                    sx={{
                      mb: 1,
                    }}
                    text={selectedEmployee?.manager?.display_name}
                  />
                </SkeletonInput>

                <SkeletonInput loading={loading} width={"90%"}>
                  <CustomTextField
                    label="Должность"
                    name="occupation"
                    size="small"
                    sx={{ width: "100%", fontSize: "12px", mb: 2, pr: 5 }}
                  />
                </SkeletonInput>
              </Grid>
              <Grid item ld={4} md={12} sm={12} xl={4} xs={12}>
                <Typography
                  sx={{
                    mb: 2,
                  }}
                  variant="subtitle1"
                >
                  Общие сведения
                </Typography>
                <CustomViewField
                  label="Дата рождения"
                  loading={false}
                  text={dayjs(selectedEmployee?.birth_date).format(
                    "DD.MM.YYYY",
                  )}
                />
                <CustomViewField
                  copyButton={true}
                  label="Email"
                  loading={false}
                  text={selectedEmployee?.email}
                />
                <CustomViewField
                  copyButton={true}
                  label="Телефон"
                  loading={false}
                  text={selectedEmployee?.phone}
                />
                <CustomViewField
                  copyButton={true}
                  label="Доп. каналы связи"
                  loading={false}
                  text={selectedEmployee?.communication_channels}
                />

                <CustomViewField
                  label="Часовой пояс"
                  loading={false}
                  text={selectedEmployee?.time_zone}
                />
                <CustomViewField
                  label="Рабочий график"
                  loading={false}
                  text={selectedEmployee?.work_schedule}
                />
                <CustomViewField
                  label="Страна"
                  loading={false}
                  text={selectedEmployee?.country}
                />
              </Grid>
              <Grid item ld={6} md={12} sm={12} xl={6} xs={12}>
                {isMobile ? (
                  <Stack
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                    sx={{ width: "100%", mt: 3 }}
                  >
                    <Button
                      disabled={loading}
                      sx={{ width: "100%" }}
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                    <Button
                      onClick={() => reset()}
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Сбросить
                    </Button>
                  </Stack>
                ) : (
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mt: 3, mb: 1 }}
                  >
                    <Grid item>
                      <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                      >
                        Сохранить
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleBack}
                        sx={{ ml: 1 }}
                        variant="text"
                      >
                        Назад
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={() => reset()}
                        sx={{ ml: 1 }}
                        variant="text"
                      >
                        Сбросить
                      </Button>
                    </Grid>
                  </Stack>
                )}
                <AlertMessage />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Card>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />

      <AlertMessage />
    </Container>
  );
};

export default EmployeesForm;
