import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contracts: [],
  employments: [],
  selectedContract: null,
  selectedEmployment: null,
  employment: null,
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setContracts(state, { payload }) {
      state.contracts = payload;
    },
    setEmployments(state, { payload }) {
      state.employments = payload;
    },
    setSelectedContract(state, { payload }) {
      const index = state.contracts.findIndex(
        (contract) => contract.id === payload,
      );
      state.selectedContract = state.contracts[index];
    },
    selectedEmployment(state, { payload }) {
      const index = state.employments.findIndex(
        (employment) => employment.id === payload,
      );
      state.selectedEmployment = state.employments[index];
    },
    setEmployment(state, { payload }) {
      state.employment = payload;
    },
  },
});

export const { actions: contractsActions, reducer: contractsReducer } =
  contractsSlice;
export default contractsSlice.reducer;
