import React, { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Card, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import BlockFormModal from "./BlockFormModal";
import CustomViewField from "./CustomViewField";
import AddEditFormButtonGroup from "./forms/AddEditFormButtonGroup";
import BaseAddEditForm from "./forms/BaseAddEditForm";
import ContactsForm from "./forms/ContactsForm";
import Iconify from "./iconify";
import MaterialTable from "./MaterialTable";
import MaterialTablePagination from "./MaterialTablePagination";
import LoadingSpinner from "./modals/loadingSpinner";
import { TableCellsWrap } from "./TableCellsWrap";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import { ButtonStack, RowOptionsStack } from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";

const Contacts = () => {
  const tableId = "contacts";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState("");
  const [confirmationId, setConfirmationId] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const isMobile = useResponsive("down", "md");

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: tableStates[tableId]?.pagination?.pageIndex || 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [columnFilters, setColumnFilters] = useState(
    tableStates[tableId]?.columnFilters || [],
  );
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const { setContacts, setSelectedContact } = useActions();
  const { updateFullSelectedClient } = useActions();
  const { setClientsContacts } = useActions();

  const { contacts, selectedContact } = useSelector(
    (state) => state.contactsReducer,
  );

  const { selectedClient } = useSelector((state) => state.clientsReducer);

  const { clientId } = useParams();

  useEffect(() => {
    const loadClientData = () => {
      try {
        const newselectedClient = async () => {
          const response = await $authHost.get(`/clients/${clientId}/`);
          return response.data;
        };
        newselectedClient().then((data) => {
          updateFullSelectedClient(data);
        });
      } catch (e) {
        console.log(e);
      }
    };
    loadClientData();
  }, [clientId, updateFullSelectedClient]);

  const tableColumns = [
    {
      accessorKey: "person.full_name",
      header: "ФИО",
      accessorFn: (row) => row.person?.full_name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "position",
      header: "Должность",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "phone",
      header: "Телефон",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "email",
      header: "Email",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteRow(row.original.id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            <Tooltip title="Редактировать">
              <IconButton
                color="inherit"
                onClick={() => handleGoToEditPage(row.original)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                color="inherit"
                onClick={() => setConfirmationId(row.original.id)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </RowOptionsStack>
        );
      },
    },
  ];

  function handleGoToEditPage(row) {
    setSelectedRow(row);
    setSelectedContact(row.id);
    setOpenForm("Update");
  }

  async function handleDeleteRow(row) {
    try {
      await $authHost.delete(`/contacts/${row}/delete/`).then((response) => {
        loadData();
        setConfirmationId(null);
      });
    } catch (e) {
      console.log(e);
    }
  }

  const handleAdd = () => {
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
    setSelectedRow(null);
  };

  const handleOpenViewPage = (row) => {
    setSelectedRow(row.original);
    setSelectedContact(row.original.id);

    openForm !== "View" && setOpenForm("View");
  };

  const UpdateAction = async (data) => {
    try {
      const updatePerson = async () => {
        await $authHost.put(`/persons/${selectedContact.person_id}/update/`, {
          full_name: data.full_name,
        });
      };
      const updateContact = async () => {
        let contact_data = data;
        contact_data["person_id"] = selectedContact.person_id;
        contact_data["client_id"] = selectedClient.source_id;
        delete contact_data.full_name;
        return await $authHost.put(
          `/contacts/${selectedContact.source_id}/update/`,
          contact_data,
        );
      };
      updatePerson().then(() => {
        updateContact().then((data) => {
          //console.log(data);
          setOpenForm("");
          loadData();
        });
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const AddAction = async (data) => {
    try {
      const newPerson = async () => {
        const response = await $authHost.post(`/persons/add/`, {
          full_name: data.full_name,
        });
        return response.data.source_id;
      };
      const newContact = async (person_id) => {
        let contact_data = data;
        contact_data["person_id"] = person_id;
        contact_data["client_id"] = selectedClient.source_id;
        delete contact_data.full_name;
        const response = await $authHost.post(`/contacts/add/`, contact_data);
        return response.data;
      };
      newPerson().then((data) => {
        newContact(data).then((data) => {
          // console.log(data);
          setOpenForm("");
          loadData();
        });
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);

    try {
      const sortStr = getSortingString(sorting);

      const response = await $authHost.get(`/contacts/all/`, {
        params: {
          client_id: selectedClient?.source_id,
          page: pagination.pageIndex + 1,
          size: pagination.pageSize,
          ...(searchText && { search: searchText }),
          ...(sortStr && { sort_by: sortStr }),
          ...columnFilters.reduce(
            (acc, column) => ({
              ...acc,
              ...{ [`${column.id}_text`]: column.value },
            }),
            {},
          ),
        },
      });

      const data = response.data.items;
      setContacts(data);
      setClientsContacts(response.data?.total);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    selectedClient?.source_id,
    setClientsContacts,
    setContacts,
    sorting,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!openForm && selectedContact) {
      setSelectedContact(null);
      // resetSandboxFieldForm()
    }
  }, [openForm, selectedContact, setSelectedContact]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <>
        <ButtonStack>
          <Button
            aria-haspopup="true"
            disabled={!selectedClient}
            onClick={handleAdd}
            size={isMobile ? "small" : "medium"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{ mr: 0 }}
            variant="contained"
          >
            Добавить контактное лицо
          </Button>
        </ButtonStack>
        {/*<AlertMessage/>*/}
        {openForm && openForm !== "View" && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ p: 3, mb: 2 }}>
                <BaseAddEditForm
                  AddAction={AddAction}
                  UpdateAction={UpdateAction}
                  action={openForm}
                  handleClose={() => setOpenForm("")}
                  resetValue={{
                    type: "",
                    position: "",
                    phone: "",
                    email: "",
                  }}
                  selectedRow={selectedRow}
                  setConfirmation={setConfirmation}
                  setLoading={setLoading}
                >
                  <ContactsForm />
                  <AddEditFormButtonGroup
                    action={openForm}
                    confirmation={confirmation}
                    handleClose={() => setOpenForm("")}
                  />
                </BaseAddEditForm>
              </Card>
            </Grid>
          </Grid>
        )}
        {openForm === "View" && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ p: 3, mb: 2 }}>
                {isMobile && (
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mb: 3 }}
                  >
                    <Grid item>
                      <Typography color="text.secondary" variant="subtitle1">
                        Просмотр
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => setOpenForm("")} sx={{ p: 0 }}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                <CustomViewField label="ФИО" text={selectedRow?.full_name} />
                <CustomViewField
                  label="Должность"
                  text={selectedRow?.position}
                />
                <CustomViewField label="Телефон" text={selectedRow?.phone} />
                <CustomViewField label="Email" text={selectedRow?.email} />
                <CustomViewField
                  label="Комментарий"
                  text={selectedRow?.notes}
                />
                <CustomViewField
                  label="Дополнительноя коммуникация"
                  text={selectedRow?.additional_communication}
                />
                <Grid item sx={{ width: "100%", mb: 2 }}>
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <Button
                      onClick={() => setOpenForm("Update")}
                      variant="contained"
                    >
                      Редактировать
                    </Button>
                    {!isMobile && (
                      <Button
                        color="primary"
                        onClick={() => setOpenForm("")}
                        sx={{ ml: 1 }}
                        variant="text"
                      >
                        Закрыть
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
        <Card sx={{ pt: 1 }}>
          <MaterialTablePagination
            columnFilters={columnFilters}
            columns={tableColumns}
            data={contacts}
            id={tableId}
            loading={loading}
            muiTableBodyCellProps={({ row }) => ({
              onClick: () => {
                handleOpenViewPage(row);
              },
              sx: {
                cursor: "pointer",
              },
            })}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={onGlobalFilterChange}
            onSortingChange={setSorting}
            pageCount={pageCount}
            pagination={pagination}
            rowCount={rowCount}
            search={searchText}
            setPagination={setPagination}
            sorting={sorting}
          />
        </Card>
        <BlockFormModal
          title="Вы не завершили действие внутри таблицы"
          when={!!confirmationId}
        />
      </>
    </>
  );
};

export default Contacts;
